<template>
  <delivery-points></delivery-points>
</template>

<script>
import DeliveryPoints from '@/components/deliverypoints/DeliveryPoints.vue'

export default {
  name: 'DeliveryPointsView',
  components: {
    DeliveryPoints
  }
}
</script>
